import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as option from "@/models/configurator/configurator_options";

import { FrameState } from "./types";

const state = {} as FrameState;

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
