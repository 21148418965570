import { UserState } from "./types";
import { User } from "@/models/user/user";
import { Roles } from "@/models/user/roles";

export const getUser = (state: UserState): User | null => {
  return state.user;
};

export const getFileId = (state: UserState): string | null => {
  return state.fileBeingEdited;
};
