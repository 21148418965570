import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import Vue from "vue";
import { Roles } from "@/models/user/roles";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/landing/NotFound.vue")
  },
  {
    path: "/error",
    name: "Error",
    props: route => ({
      props: route.params
    }),
    component: () => import("../views/landing/Error.vue")
  },
  {
    path: "/resubmit",
    name: "Resubmit",
    component: () => import("../views/landing/FormExpired.vue")
  },
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/CollectionMenuNew.vue")
  },
  {
    path: "/collectionmenu",
    name: "CollectionMenu",
    component: () => import("@/views/CollectionMenuNew.vue")
  },
  // {
  //   path: "/collectionmenu-original",
  //   name: "CollectionMenuNew",
  //   component: () => import("@/views/archive/CollectionMenu.vue")
  // },
  {
    path: "/quickship",
    component: () => import("../views/QuickShip.vue"),
    meta: { requiresVerification: true },
    children: [
      {
        path: "",
        name: "QuickShipMain",
        component: () => import("@/components/quick_ship/ProductLines.vue"),
        meta: { requiresVerification: true }
      },
      {
        path: "/quickship/:handle",
        name: "QuickShipUmbrellas",
        component: () => import("@/components/quick_ship/Umbrellas.vue"),
        meta: { requiresVerification: true }
      }
    ]
  },
  // {
  //   path: "/view",
  //   name: "ViewOnly",
  //   component: () => import("@/views/ViewOnly.vue")
  // },
  {
    path: "/view",
    name: "View",
    component: () => import("@/views/View.vue")
  },
  {
    path: "/designer",
    component: () => import("@/views/Designer.vue"),
    children: [
      {
        path: "",
        name: "Shape",
        component: () =>
          import("@/components/designer/sidebar/shape/ShapeSidebar.vue")
      },
      {
        path: "/designer/layout",
        name: "Layout",
        component: () =>
          import("@/components/designer/sidebar/layout/LayoutSidebar.vue")
      },
      {
        path: "/designer/base",
        name: "Base",
        component: () =>
          import("@/components/designer/sidebar/base/BaseSidebar.vue")
      },
      {
        path: "/designer/frame",
        name: "Frame",
        component: () =>
          import("@/components/designer/sidebar/frame/FrameSidebar.vue")
      },
      {
        path: "/designer/canopy",
        name: "Canopy",
        component: () =>
          import("@/components/designer/sidebar/canopy/CanopySidebar.vue")
      },
    ]
  },
  {
    path: "/quote",
    name: "RequestQuote",
    props: route => ({
      props: route.params as any
    }),
    component: () => import("@/views/RequestQuote.vue")
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: () => import("@/views/user/UserProfile.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/user/designs",
    name: "UserDesigns",
    component: () => import("@/views/user/UserDesigns.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/contest/admin",
    name: "ContestAdmin",
    component: () => import("@/components/contest/ContestAdmin.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/contest/entry/:id",
    name: "ContestEntryView",
    component: () => import("@/components/contest/ContestEntryView.vue"),
  },
  {
    path: "/login",
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        `${process.env.VUE_APP_LOGIN_URL}?returnURL=${process.env.VUE_APP_URL}/designer`
      );
      // window.location.replace('http://localhost:8080?returnURL=http://localhost:8081/designer')
    }
  },
  {
    path: "/view/login",
    props: route => ({
      props: route.query
    }),
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        `${process.env.VUE_APP_LOGIN_URL}?returnURL=${process.env.VUE_APP_URL}/view?design=redirect`
        // `${process.env.VUE_APP_LOGIN_URL}?returnURL=${process.env.VUE_APP_URL}/view?design=9aXdMecxnOuZag`
      );
      // window.location.replace(
      //   `${process.env.VUE_APP_LOGIN_URL}?returnURL=${process.env.VUE_APP_URL}/view?design=${this.props}`
      // );
      // window.location.replace('http://localhost:8080?returnURL=http://localhost:8081/designer')
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  //@ts-ignore
  const isLoggedIn = store.state.global.isLoggedIn;
  //@ts-ignore
  const userRole = store.state.global.userRole;

  if (to.matched.some(record => record.meta.requiresVerification)) {
    // this route requires auth, check if logged in
    // if not, redirect
    if (!isLoggedIn || userRole === Roles.Unverified) {
      next({
        path: "/designer"
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect
    if (!isLoggedIn) {
      next({
        path: "/designer"
      });
      next();
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
