

































import UIkit from "uikit";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DisclaimerModal extends Vue {
  mounted() {
    //open disclaimer modal by default
    this.$nextTick(() => {
      // open disclaimer modal if local storage is false
      if (localStorage.getItem("disclaimerModalClosed") !== "true") {
        UIkit.modal("#disclaimer-modal-container").show();
      }
    });

    // function that handles modal close
    this.$root.$on("modalClose", () => {
      UIkit.modal("#disclaimer-modal-container").hide();
      // set local storage to true so that modal does not open again
      localStorage.setItem("disclaimerModalClosed", "true");
    });

    this.$root.$on("modalOpen", () => {
      //set local storage to false so that modal opens again
      localStorage.setItem("disclaimerModalClosed", "false");
    });
  }
}
