

















































import { Component, Prop, Vue } from "vue-property-decorator";
/** SEE PRICING DIAGRAM FOR MORE INFORMATION */
@Component
export default class PricingBreakdown extends Vue {
  @Prop() umbrellaName!: string;
  @Prop() umbrellaModel!: string;
  @Prop() baseName!: string | null;
  @Prop() baseModel!: string | null;
  @Prop({ default: 0 }) total!: number;
  @Prop({ default: 0 }) umbrellaPrice!: number;
  @Prop({ default: 0 }) basePrice!: number;
}
