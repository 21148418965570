












import { Component, Vue } from "vue-property-decorator";
import ViewerPlugin from "@/plugins/viewer";
import { ConfigCreator } from "@/mixins/configurator";
import { mixins } from "vue-class-component";
import { GenericError, ViewerError } from "@/services/error_service";
import { EventBus } from "@/event-bus";
import { Stem } from "@/models/configurator/configurator_options";
import { StockMixin } from "@/mixins/stock";

@Component
export default class Configurator extends mixins(ConfigCreator, StockMixin) {
  protected loader: any | null = null;
  created() {
    EventBus.$on(
      "getStaticImages",
      async (callback: (images: string[]) => Promise<any>) => {
        const images = await this.getImages();
        callback(images);
      }
    );
    if (this.$route.path === '/designer') {
    this.loader = this.$loading.show(
      { opacity: 0.9 },
      {
        before: this.$createElement("h1", {
          domProps: { innerHTML: "Loading..." },
        }),
      }
    );
    }
  }

  protected async frameLoaded(): Promise<void> {
    ViewerPlugin.init(Vue);
    try {
      const data = await this.transformDataToViewerJson();
      this.$viewer.SetAssetPath(process.env.VUE_APP_CDN_URL + "/sd");
      /** Exception logic for bases that include 18ST2 stems in the base's model name. These are specific to giant umbrellas, which MUST have an 18ST2 stem
       */
      if (
        data.base.base &&
        data.base.base !== Stem.Stem18ST2 &&
        data.base.base.indexOf("18ST2") > -1
      ) {
        data.base.base = data.base.base.slice(0, -6);
        data.base.baseStem = Stem.Stem18ST2;
      }
      await this.$viewer.StartApp(data);
      this.$viewer.addEventListener("viewer.loaded", (event) => {
        EventBus.$emit("ready"); // TLT_2023-05-22: this call doesn't fulfill...
        this.$emit("ready");
        if (this.loader) {
        this.loader.hide();
        }
      });
    } catch (err) {
      console.log(err)
      ViewerError.redirect(err);
    }
  }

  protected async getImages(resolution?: {
    x: number;
    y: number;
  }): Promise<string[]> {
    let images: string[] = [];
    try {
      let res;
      if (resolution) {
        res = await this.$viewer.RenderStaticImage(resolution);
      } else {
        res = await this.$viewer.RenderStaticImage({ x: 600, y: 600 });
      }
      if (res) {
        images = res;
      } else {
        throw new Error("Error obtaining images");
      }
    } catch (err) {
      GenericError.popup(err.message);
    }
    return images;
  }
}
