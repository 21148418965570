








import { Component, Vue } from "vue-property-decorator";

@Component
export default class Clear extends Vue {
  mounted() {
    ((this.$refs.clear as Vue).$el as SVGSVGElement).setAttribute(
      "width",
      "8px"
    );
    ((this.$refs.clear as Vue).$el as SVGSVGElement)
      .getElementById("Group_1459")!
      .setAttribute("stroke", "red");
    ((this.$refs.clear as Vue).$el as SVGSVGElement)
      .getElementById("Group_1459")!
      .setAttribute("stroke-width", "5px");
  }
}
