




































































import { Component, Vue } from "vue-property-decorator";
import ContestService from "@/services/contest_service";
import { mixins } from "vue-class-component";
import { ConfigCreator } from "@/mixins/configurator";
import { EventBus } from "@/event-bus";

@Component
export default class ContestForm extends mixins(ConfigCreator) {
protected contestService = new ContestService();
  protected terms = false;
  protected termsError = false;
  protected valid = false;
  protected entry = {
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    phone: "",
    name: "",
    image: "",
    category: null,
  };
  protected rules = {
    required: (value: any) => !!value || "Required.",
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    phone: (value: string) => {
      const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      return pattern.test(value) || "Invalid phone number.";
    },
  };

protected submit(): void {
     (this.$refs.form! as any).validate()
     if (this.valid) {
            EventBus.$emit("getStaticImages", this.submitContestEntry);
     }
}

protected async submitContestEntry(images: string[]): Promise<void> {
    try {
     const body = await this.transformDataToConfigFile();
     await this.contestService.createFile(this.entry as any, body, images);
     this.$emit('submitted')
    } catch(err) {
        console.log(err)
    }
}

}
