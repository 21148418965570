









import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore
} from "@/mixins/store";
@Component
export default class LeadTime extends mixins(
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore
) {
  // There should be three tiers of lead times:
  // QuickShip
  // Stock
  // Custom
}
