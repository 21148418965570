import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { LayoutState } from "./types";
import { Preset } from "@/models/configurator/configurator_options";

const state = {} as LayoutState;

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
