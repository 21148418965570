
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ContestForm from "@/components/contest/ContestForm.vue";
import ContestSubmitted from "@/components/contest/ContestSubmitted.vue";

@Component({
  components: {
    ContestForm,
    ContestSubmitted,
  },
})
export default class ContestModal extends Vue {
  @Prop({default: false}) entrySubmitted!: boolean;

  protected submitted(): void {
    this.$emit('submitted')
  }

  protected close(): void {
    this.$emit('close')
  }
}
