
































import { LayoutStore } from "@/mixins/store";
import { ProductLineName } from "@/models/products/collection";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CameraControls extends mixins(LayoutStore) {
  protected closeOnClick = true;
  protected ProductLineName = ProductLineName;

  protected async changeCamera(camPosition: 0 | 1 | 2 | 3): Promise<void> {
    await this.$viewer.GoToCamera(camPosition);
  }

  get front(): string {
    let label = "";
    if (
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich
    ) {
      label = "monterey";
    } else {
      label = this.collection.handle.replaceAll("_", "-");
    }
    return require(`../../../assets/images/cam/${label}-front-angle.png`); // "require" is needed because of webpack issue with compiling dynamic image urls
  }

  get top(): string {
    let label = "";
    if (
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich
    ) {
      label = "monterey";
    } else {
      label = this.collection.handle.replaceAll("_", "-");
    }
    return require(`../../../assets/images/cam/${label}-top-angle.png`); // "require" is needed because of webpack issue with compiling dynamic image urls
  }

  get highAngle(): string {
    let label = "";
    if (
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich
    ) {
      label = "monterey";
    } else {
      label = this.collection.handle.replaceAll("_", "-");
    }
    return require(`../../../assets/images/cam/${label}-high-angle.png`); // "require" is needed because of webpack issue with compiling dynamic image urls
  }
}
