import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

export type LayoutState = CustomUmbrella["layout"];

export type LayoutContext = {
  state: LayoutState;
  dispatch: (action: LayoutActions, payload?: any) => any;
  commit: (mutation: LayoutMutations, payload: any) => any;
};

export enum LayoutMutations {
  SetCollection = "setCollection",
  SetPreset = "setPreset"
}

export enum LayoutActions {
  AddCollection = "addCollection",
  AddPreset = "addPreset"
}

export enum LayoutGetters {
  GetCollection = "getCollection",
  GetPreset = "getPreset"
}
