




import { Component, Prop } from "vue-property-decorator";
import { APIError, BadRequest, GenericError } from "@/services/error_service";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { UmbrellaData } from "@/mixins/umbrella";
import { mixins } from "vue-class-component";
import SpecService from "@/services/spec_service";
import { EventBus } from "@/event-bus";

@Component
export default class Download extends mixins(UmbrellaData) {
  @Prop({ default: null }) images?: string[];
  protected specService = new SpecService();

  protected async download(): Promise<void> {
    const loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Generating spec sheet...") }
    );
    if (!this.images) {
      try {
        EventBus.$emit("getStaticImages", this.getPdf);
        setTimeout(() => {
          loader.hide();
        }, 1000);
      } catch (err) {
        GenericError.popup(err.message);
        loader.hide();
      }
    } else {
      this.getPdf(this.images);
      setTimeout(() => {
        loader.hide();
      }, 1000);
    }
  }

  protected async getPdf(images: string[]) {
    const umbrella = this.getUmbrellaObject();
    await this.createPdfRequest({
      images: images,
      specs: umbrella
    });
  }

  protected async createPdfRequest(
    body: { images: string[]; specs: CustomUmbrella },
    loader?: any
  ): Promise<any> {
    let pdf;
    try {
      const res = await this.specService.downloadSpecs(body);
      pdf = res;
      this.forcePdfDownload(pdf);
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else if (err instanceof GenericError) {
        GenericError.popup(err.message);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      if (loader) {
        setTimeout(() => {
          loader.hide();
        }, 2000);
      }
    }
    return pdf;
  }

  protected forcePdfDownload(pdf: Blob): void {
    const date = new Date().toLocaleDateString("en-US").replace(/\//g, "-");
    const url = window.URL.createObjectURL(new Blob([pdf]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Frankford_Umbrella_Specs_" + date + ".pdf");
    document.body.appendChild(link);
    link.click();
  }
}
