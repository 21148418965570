import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

export type ShapeState = CustomUmbrella["shape"];

export type ShapeContext = {
  state: ShapeState;
  dispatch: (action: ShapeActions, payload?: any) => any;
  commit: (mutation: ShapeMutations, payload: any) => any;
};

export enum ShapeMutations {
  SetModel = "setModel"
}

export enum ShapeActions {
  AddModel = "addModel"
}

export enum ShapeGetters {
  GetModel = "getModel"
}
