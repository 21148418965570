import { Preset } from "@/models/configurator/configurator_options";
import { FabricState } from "./types";

export const getAllowValance = (state: FabricState) : boolean => {
  return state.allowValance;
};

export const getAllowVent = (state: FabricState) : boolean => {
  return state.allowVent;
};

