import { FabricContext, FabricMutations } from "./types";


export const allowValance = (
  context: FabricContext,
  value: boolean
) => {
  context.commit(FabricMutations.SetAllowValance, value);
};


export const allowVent = (
  context: FabricContext,
  value: boolean
) => {
  context.commit(FabricMutations.SetAllowVent, value);
};
