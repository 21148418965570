import {
  ConfigFile,
  QuickshipConfigFile
} from "@/models/configurator/config_file";
import axios, { AxiosResponse } from "axios";
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from "./error_service";

axios.defaults.withCredentials = true;
axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

/**
 * Creates a new Config File Service

 * @example
 * const configFileService = new ConfigFileService();
 * configFileService.getFile();
 */

export default class ConfigFileService {
  /**
   * @param {string} id string value of ObjectId
   * @returns {ConfigFile} - config file
   */
  public async getFile(id: string): Promise<ConfigFile> {
    let file;
    const url = process.env.VUE_APP_API_NODE_URL + "/configurator/" + id;
    try {
      const res: AxiosResponse = await axios.get(url);
      file = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Configuration file not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return file;
  }

  public async deleteFile(id: string): Promise<void> {
    let file;
    const url = process.env.VUE_APP_API_NODE_URL + "/configurator/" + id;
    try {
      await axios.delete(url);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Configuration file not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return file;
  }

  public async updateFile(
    req: ConfigFile,
    id: string,
    images?: string[]
  ): Promise<ConfigFile> {
    let file;
    const url = process.env.VUE_APP_API_NODE_URL + "/configurator/" + id;
    try {
      let res: AxiosResponse;
      if (images) {
        res = await axios.put(url, { ...req, image: images[0] });
      } else {
        res = await axios.put(url, req);
      }
      file = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Configuration file not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return file;
  }

  /**
   *
   * @returns {[ConfigFile]} - config files
   */
  public async getQuickshipFiles(): Promise<QuickshipConfigFile[]> {
    let files;
    const url = process.env.VUE_APP_API_NODE_URL + "/quickship";
    try {
      const res: AxiosResponse = await axios.get(url);
      files = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Configuration files not found.",
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return files;
  }

  /**
   * @param {ConfigFile} req request body
   * @returns {ConfigFile} - newly created config file with _id
   */
  public async createFile(
    req: ConfigFile,
    images?: string[]
  ): Promise<ConfigFile> {
    let createdFile: ConfigFile;
    const url = process.env.VUE_APP_API_NODE_URL + "/configurator";
    try {
      const options = {
        withCredentials: true
      };
      let res: AxiosResponse;
      if (images) {
        res = await axios.post(url, { ...req, image: images[0] });
      } else {
        res = await axios.post(url, req);
      }
      createdFile = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return createdFile;
  }

  /**
   * @param {ConfigFile} req request body
   * @returns {ConfigFile} - newly created config file with _id
   */
  public async createShareFile(
    req: ConfigFile,
    expire = true
  ): Promise<ConfigFile> {
    let url = process.env.VUE_APP_API_NODE_URL + "/configurator/share";
    let createdFile: ConfigFile;
    if (!expire) {
      url += "?noExpire";
    }
    try {
      const res: AxiosResponse = await axios.post(url, req);
      createdFile = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return createdFile;
  }

  public async getShareFile(shareId: string): Promise<ConfigFile> {
    let file;
    const url =
      process.env.VUE_APP_API_NODE_URL + "/configurator/share/" + shareId;
    try {
      const res: AxiosResponse = await axios.get(url);
      file = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          err.response.data.message,
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return file;
  }
}
