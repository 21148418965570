import { BaseState } from "./modules/configurator/base/types";
import { CanopyState } from "./modules/configurator/canopy/types";
import { FabricState } from "./modules/configurator/fabric/types";
import { FrameState } from "./modules/configurator/frame/types";
import { LayoutState } from "./modules/configurator/layout/types";
import { ShapeState } from "./modules/configurator/shape/types";
import { StockState } from "./modules/configurator/stock/types";
import { UserState } from "./modules/user/types";

export enum StoreModule {
  Global = "global/",
  User = "user/",
  Canopy = "canopy/",
  Base = "base/",
  Frame = "frame/",
  Layout = "layout/",
  Shape = "shape/",
  Stock = "stock/",
  Fabric = "fabric/"
}

export interface RootState {
  global: any;
  user: UserState;
  canopy: CanopyState;
  base: BaseState;
  frame: FrameState;
  layout: LayoutState;
  shape: ShapeState;
  stock: StockState;
  fabric: FabricState;
}
