import { Component, Vue } from "vue-property-decorator";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import store from "@/store";
@Component
export class UmbrellaData extends Vue {
  public getUmbrellaObject(): CustomUmbrella {
    const data = {
      //@ts-ignore
      layout: store.state.layout,
      //@ts-ignore,
      shape: store.state.shape,
      //@ts-ignore,
      base: store.state.base,
      //@ts-ignore,
      frame: store.state.frame,
      //@ts-ignore,
      canopy: store.state.canopy
    } as CustomUmbrella;
    return data;
  }
}
