import { Roles } from "@/models/user/roles";
import { GlobalState } from "./types";

export const setLoading = (state: GlobalState, value: boolean) => {
  state.isLoading = value;
};

export const setLoggedIn = (state: GlobalState, value: boolean) => {
  state.isLoggedIn = value;
};

export const setRole = (state: GlobalState, value: Roles | null) => {
  state.userRole = value;
};
