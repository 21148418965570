import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

export type FrameState = CustomUmbrella["frame"];

export type FrameContext = {
  state: FrameState;
  dispatch: (action: FrameActions, payload?: any) => any;
  commit: (mutation: FrameMutations, payload: any) => any;
};

export enum FrameMutations {
  SetFinish = "setFinish",
  SetFinial = "setFinial",
  SetFinialFinish = "setFinialFinish",
  SetBottomPole = "setBottomPole",
  SetSpigot = "setSpigot"
}

export enum FrameActions {
  AddFinish = "addFinish",
  AddFinial = "addFinial",
  AddFinialFinish = "addFinialFinish",
  AddBottomPole = "addBottomPole",
  AddSpigot = "addSpigot",
  AddAllFrameData = "addAllFrameData"
}

export enum FrameGetters {
  GetFinish = "getFinish",
  GetFinial = "getFinial",
  GetFinialFinish = "getFinialFinish",
  GetBottomPole = "getBottomPole",
  GetSpigot = "getSpigot",
  GetAllFrameData = "getAllFrameData"
}
