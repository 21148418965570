import { Preset } from "@/models/configurator/configurator_options";
import { Collection } from "@/models/products/collection";
import { FabricState } from "./types";

export const setAllowValance = (state: FabricState, value: boolean) => {
  state.allowValance = value;
};

export const setAllowVent = (state: FabricState, value: boolean) => {
  state.allowVent = value;
};

