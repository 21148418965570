





























































import { ProductLineName } from "@/models/products/collection";
import { Component, Vue, Prop } from "vue-property-decorator";
import imagedata from "@/assets/gallery/gallery_images.json";

@Component
export default class Gallery extends Vue {
  @Prop() productHandle!: ProductLineName;
  protected dialog = false;
  protected model = 0;

  get cdnUrl(): string {
    return (
      `${process.env.VUE_APP_CDN_URL}/sd/gallery/` + this.productHandle + `/`
    );
  }
  data() {
    const imgArr = imagedata.filter((m) => m.model === this.productHandle);
    return {
      gImages: imgArr,
    };
  }
}
