import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { Component, Vue } from "vue-property-decorator";
import { FabricActions, FabricGetters } from "@/store/modules/configurator/fabric/types";

@Component
export class FabricMixin extends Vue {
  @(namespace(StoreModule.Fabric).Getter(FabricGetters.GetAllowValance))
  allowValanceFabric!: boolean;
  @(namespace(StoreModule.Fabric).Action(FabricActions.AllowValance))
  allowValance!: (value: boolean) => Promise<void>;

  @(namespace(StoreModule.Fabric).Getter(FabricGetters.GetAllowVent))
  allowVentFabric!: boolean;
  @(namespace(StoreModule.Fabric).Action(FabricActions.AllowVent))
  allowVent!: (value: boolean) => Promise<void>;
}
