import { UserContext, UserMutations } from "./types";
import { User } from "@/models/user/user";

export const addUser = (context: UserContext, userData: User | null) => {
  context.commit(UserMutations.SetUser, userData);
};

export const addFileId = (context: UserContext, name: string | null) => {
  context.commit(UserMutations.SetFileId, name);
};
