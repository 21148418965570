import { User } from "@/models/user/user";

export interface UserState {
  user: User | null;
  fileBeingEdited: string | null;
}

export type UserContext = {
  state: UserState;
  dispatch: (action: UserActions, payload?: any) => any;
  commit: (mutation: UserMutations, payload: any) => any;
};

export enum UserMutations {
  SetUser = "setUser",
  SetFileId = "setFileId"
}

export enum UserActions {
  AddUser = "addUser",
  AddFileId = "addFileId"
}

export enum UserGetters {
  GetUser = "getUser",
  GetFileId = "getFileId"
}
