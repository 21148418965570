import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

export type BaseState = CustomUmbrella["base"];

export type BaseContext = {
  state: BaseState;
  dispatch: (action: BaseActions, payload?: any) => any;
  commit: (mutation: BaseMutations, payload: any) => any;
};

export enum BaseMutations {
  SetBase = "setBase",
  SetBaseFinish = "setBaseFinish",
  SetBaseOptions = "setBaseOptions",
  SetBaseStem = "setBaseStem"
}

export enum BaseActions {
  AddBase = "addBase",
  AddBaseFinish = "addBaseFinish",
  AddBaseOptions = "addBaseOptions",
  AddAllBaseData = "addAllBaseData",
  AddBaseStem = "addBaseStem"
}

export enum BaseGetters {
  GetBase = "getBase",
  GetBaseFinish = "getBaseFinish",
  GetBaseOptions = "getBaseOptions",
  GetAllBaseData = "getAllBaseData",
  GetBaseStem = "getBaseStem"
}
