


















































import { Component } from "vue-property-decorator";
import { APIError, NotFoundError } from "@/services/error_service";
import { mixins } from "vue-class-component";
import Configurator from "../components/designer/Configurator.vue";
import { Collection } from "@/models/products/collection";
import { ProductType } from "@/models/products/product";
import ProductService from "@/services/product_service";
import { UmbrellaType } from "@/models/products/umbrella";

@Component
export default class QuickShip extends mixins(Configurator) {
  protected show = false;
  protected umbrellas: Collection[] = [];
  protected productService = new ProductService();
  protected UmbrellaType = UmbrellaType;

  async created() {
    this.show = true;
  }

  protected umbrellasFiltered(type: UmbrellaType) {
    return this.umbrellas.filter((umbrella) => umbrella.umbrella_type === type);
  }

  protected getImg(handle: string, type: UmbrellaType): string {
    let img = "https://via.placeholder.com/250x165";
    if (type === UmbrellaType.Market) {
      img = require(`../assets/images/${handle}.png`); // "require" is needed because of webpack issue with compiling dynamic image urls
    }
    return img;
  }

  protected async getProductLines(): Promise<Collection[]> {
    let umbrellas: Collection[] = [];
    try {
      const res = await this.productService.getProducts({
        category: ProductType.Umbrella,
        sort: "productline",
      });
      umbrellas = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrellas;
  }
}
