import { Roles } from "@/models/user/roles";
import { GlobalState } from "./types";

export const getLoading = (state: GlobalState): boolean => {
  return state.isLoading;
};

export const getLoggedIn = (state: GlobalState): boolean => {
  return state.isLoggedIn;
};

export const getRole = (state: GlobalState): Roles | null => {
  return state.userRole;
};
