
export type StockState = {
  stock: boolean;
};

export type StockContext = {
  state: StockState;
  dispatch: (action: StockActions, payload?: any) => any;
  commit: (mutation: StockMutations, payload: any) => any;
};

export enum StockMutations {
  SetStock = "setStock"
}

export enum StockActions {
  AddStock = "addStock"
}

export enum StockGetters {
  GetStock = "getStock"
}
