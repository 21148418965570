import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { Component, Vue } from "vue-property-decorator";
import { StockActions, StockGetters } from "@/store/modules/configurator/stock/types";

@Component
export class StockMixin extends Vue {
  @(namespace(StoreModule.Stock).Getter(StockGetters.GetStock))
  isStockOnly!: boolean;
  @(namespace(StoreModule.Stock).Action(StockActions.AddStock))
  addStock!: (value: boolean) => Promise<void>;
}
