






















































































import { Component, Vue } from "vue-property-decorator";
import ContestModal from "@/components/contest/ContestModal.vue";

@Component({
  components: {
    ContestModal,
  },
})
export default class ContestEnterPopup extends Vue {
  protected showSlide = false;
  protected showDialog = false;
  protected entrySubmitted = false;

  protected triggerOpen(event: Event) {
    const slider = document.getElementById("slider");
    const mainc = document.getElementById("main");
    setTimeout(() => mainc!.classList.toggle("open"), 100);
    slider!.classList.toggle("slide-down");
  }
  protected triggerClose(event: Event) {
    const slider = document.getElementById("slider");
    const mainc = document.getElementById("main");
    setTimeout(() => mainc!.classList.toggle("open"), 500);
    slider!.classList.toggle("slide-down");
  }

  protected close(): void {
    this.showDialog = false;
    this.entrySubmitted = false;
  }

  protected submitted(): void {
    this.entrySubmitted = true;
  }

  protected showModal(): void {
    this.entrySubmitted = false;
    this.showDialog = true;
  }
}
