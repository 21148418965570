





















import { Component } from "vue-property-decorator";
import StockWarning from "@/components/designer/StockWarning.vue";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch } from "@/models/products/fabric";
import { ribAttachment } from "@/mixins/business_logic/global/rib_rules";
import {
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
} from "@/mixins/store";
import { ConfigCreator } from "@/mixins/configurator";
import { UmbrellaModel } from "@/models/products/umbrella";
import { ProductLineName } from "@/models/products/collection";
import { StockMixin } from "@/mixins/stock";
import { EventBus } from "@/event-bus";
@Component({
  components: {
    StockWarning,
  },
})
export default class Stock extends mixins(
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
  ConfigCreator,
  StockMixin
) {
  protected showModal = false;
  protected ruleBProducts = [ProductLineName.Laurel, ProductLineName.Avalon]; // These productlines use set B of stock rules. The rest of the productlines are set A.
  protected ruleBModelExceptions = ["844FA03", "844FC03", "844FWB03 "]; // These umbrella models are in productlines that fall under rule set B, but are EXCEPTIONS and should have set A applied. There are no set A exceptions
  protected ribAttachment = ribAttachment;
  protected ProductLineName = ProductLineName;

  created() {
    this.$emit("isStock", this.isStockOnly);
  }

  get toggleImg(): string {
    let img;
    if (this.isStockOnly) {
      img = require("../../assets/images/toggle-true.png");
    } else {
      img = require("../../assets/images/toggle-false.png");
    }
    return img;
  }

  protected toggleStock(): void {
    if (!this.isStockOnly) {
      this.showModal = true;
    } else {
      this.$emit("isStock", !this.isStockOnly);
      this.addStock(false)
    }
  }

  /**
   * Currently two different sets of stock rules based on productline & a few exceptions based on model.
   */
  protected confirm(): void {
    this.addStock(true)
    this.$emit("isStock", true);
    this.showModal = false;
    const applyRuleA =
      !this.ruleBProducts.includes(this.collection.handle as ProductLineName) ||
      this.ruleBModelExceptions.includes(this.umbrellaModel!.model);
    this.resetUmbrellaToStock(applyRuleA ? "A" : "B");
  }

  protected close(): void {
    this.showModal = false;
  }

  /**
   *  RULE SET B --> Patio and beach: valance is stock, with the exception of 844FA03 and 844FC03 (Catalina), and 844FWB03 (Avalon).
   */
  protected async resetUmbrellaToStock(
    ruleSet: "A" | "B" = "A"
  ): Promise<void> {
    let swatch = {} as FabricSwatch;
    if (this.mainFabric[0].grade === "a") {
      swatch = this.mainFabric[0];
    } else {
      swatch = await this.getFabricSwatch("R-172");
    }
    await this.addPreset(option.Preset.S1);
    await this.addMainCanopy(option.Panel.Solid);
    await this.addVentMiddle(option.Panel.Solid);
    await this.addBindingFabric(null);
    if (this.collection.handle === ProductLineName.Greenwich || this.collection.handle === ProductLineName.Eclipse) {
      await this.addRib(option.Rib.Grommets);
    } else if (ribAttachment.pockets.includes(this.collection.handle)) {
      await this.addRib(option.Rib.Pockets);
      await this.addRibFabric(swatch);

    } else {
      await this.addRib(null);
       await this.addRibFabric(null);
    }

    await this.addFinial(option.Finial.Ball);

    if (ruleSet === "A") {
      await this.addValance(option.Valance.Canopy);
    } else {
      await this.addValance(option.Valance.Standard);
      if (this.valanceFabric[0] && this.valanceFabric[0].grade === "a") {
        swatch = this.valanceFabric[0];
      } else {
        swatch = await this.getFabricSwatch("R-172");
      }
      await this.addValanceFabric([swatch]);
    }

    await this.addVentMiddleFabric([]);
    await this.addMainTrimFabric(swatch);
    await this.addMainTrimFabricInner(swatch);

    await this.addMainFabric([swatch]);
    // if (ribAttachment.pockets.includes(this.collection.handle)) {
    //   await this.addRibFabric(swatch);
    // } else {
    //   await this.addRibFabric(null);
    // }

    await this.addVentTopFabric([swatch]);
    await this.addVentTrimFabric(swatch);
    await this.resetUmbrellaViewer(ruleSet);
  }

  protected async resetUmbrellaViewer(ruleSet: "A" | "B" = "A"): Promise<void> {
    const currentModel: UmbrellaModel = this.umbrellaModel!;
    const data = await this.transformDataToConfigFile();
    await this.$viewer.ChangeCanopyFabric(
      this.mainFabric[0].fabric_scale,
      data.canopy.mainFabric[0]
    );
    if (currentModel.attributes && currentModel.attributes.vent) {
      await this.$viewer.ChangeVent("single");
      await this.$viewer.ChangeTopVentFabric(
        this.ventTopFabric[0].fabric_scale,
        data.canopy.ventTopFabric[0]
      );
    }
    await this.$viewer.ChangeValance(data.canopy.valance);
    if (ruleSet === "B") {
      await this.$viewer.ChangeValanceFabric(
        this.valanceFabric[0].fabric_scale,
        data.canopy.valanceFabric[0]
      );
    } else {
      await this.$viewer.ChangeCanopyLayout(data.layout.preset); // this is bugging on Laurel & Avalon
    }
    await this.$viewer.ChangeBindingFabric(data.canopy.bindingFabric);
    await this.$viewer.ChangeRibAttachment(
      data.canopy.rib,
      data.canopy.rib === option.Rib.Grommets ? undefined : data.canopy.mainFabric[0]
    );
    await this.$viewer.ChangeFinial(data.frame.finial);
    await this.$viewer.ChangeVentTrim(data.canopy.ventTrimFabric);
    await this.$viewer.ChangeCanopyTrim(
      data.canopy.mainTrimFabric,
      data.canopy.mainTrimFabricInner
    );
  }
}
