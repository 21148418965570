














import { Component, Prop, Vue, Model } from "vue-property-decorator";
/**
 * SLOT is required!
 */

@Component
export default class RadioInput extends Vue {
  @Prop() value!: string;
  @Prop() name!: string;

  @Model("change", { type: String }) readonly checked!: string; // https://vuejs.org/v2/api/#model

  get labelid(): string {
    return this.$slots.default
      ? this.$slots
          .default![0].text!.trim()
          .replace(" ", "-")
          .toLowerCase()
      : this.value;
  }
}
