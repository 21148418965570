import { BaseModel } from "./base";
import { Collection } from "./collection";
import { FinishDisplayName, FinishHandle } from "./finish";
import { PriceRes } from "../pricing/price";
import { UmbrellaModel } from "./umbrella";

export interface SingleProductRes {
  product: {
    _id: string;
    model: string;
    _type: string;
    display_name: string;
    finishes?: { hande: FinishHandle; display_name: FinishDisplayName }[];
    line_art_url?: string;
    image?: string;
    type: ProductType;
    price?: PriceRes;
    size?: string[];
    shape?: string[];
    attributes?: any[];
    hardware?: any[];
    mounts?: BaseModel[];
    accessories?: SingleProductRes[];
    is_umbrella_specific?: boolean;
    product_line: Collection[];
  };
}

export interface SingleProductWithBasesRes {
  product: UmbrellaModel;
  bases: BaseModel[];
}

export interface ProductListRes {
  data?: Collection[];
  products?: any[];
  bases?: BaseModel[];
}

export enum ProductType {
  Chair = "chair",
  Base = "base",
  Accessory = "accessory",
  Umbrella = "umbrella"
}
