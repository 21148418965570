



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class SmallInfoModal extends Vue {
  mounted() {
    this.setImageSize();
  }

  protected setImageSize(): void {
    const img = this.$slots.img
      ? (this.$slots.img![0]!.elm! as SVGSVGElement)
      : "";
    if (img) {
      img.setAttribute("width", "200");
    }
  }
}
