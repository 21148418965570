import { ProductLineName } from '@/models/products/collection';

export const ribAttachment: {pockets: string[], grommets: string[]} = 
{
  pockets: [
    ProductLineName.MontereyPulleyLift,
    ProductLineName.MontereyAutoTilt,
    ProductLineName.MontereyCrank,
    ProductLineName.Catalina,
    ProductLineName.Avalon
  ],
  grommets: [
    ProductLineName.Greenwich,
    ProductLineName.MontereyPulleyLift,
    ProductLineName.MontereyAutoTilt,
    ProductLineName.MontereyCrank,
    ProductLineName.Eclipse,
    ProductLineName.Aurora,
  ],
  // Laurel & Emerald Coast have no attachments
  // Giants have no attachments
}