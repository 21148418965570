import { Roles } from "@/models/user/roles";
import { GlobalContext, GlobalMutations } from "./types";

export const addLoading = (context: GlobalContext, value: boolean) => {
  context.commit(GlobalMutations.SetLoading, value);
};

export const addLoggedIn = (context: GlobalContext, value: boolean) => {
  context.commit(GlobalMutations.SetLoggedIn, value);
};

export const addRole = (context: GlobalContext, value: Roles | null) => {
  context.commit(GlobalMutations.SetRole, value);
};
