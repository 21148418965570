import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { StockState } from "./types";


const state = {
  stock: false
} as StockState;

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
