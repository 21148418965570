
export type FabricState = {
  allowValance: boolean;
  allowVent: boolean;
};

export type FabricContext = {
  state: FabricState;
  dispatch: (action: FabricActions, payload?: any) => any;
  commit: (mutation: FabricMutations, payload: any) => any;
};

export enum FabricMutations {
  SetAllowValance = "setAllowValance",
  SetAllowVent = "setAllowVent"
}

export enum FabricActions {
  AllowValance = "allowValance",
  AllowVent = "allowVent",
}

export enum FabricGetters {
  GetAllowValance = "getAllowValance",
  GetAllowVent = "getAllowVent"
}
