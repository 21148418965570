import { ShapeContext, ShapeMutations } from "./types";

import { UmbrellaModel } from "@/models/products/umbrella";

export const addModel = (
  context: ShapeContext,
  value: UmbrellaModel | null
) => {
  context.commit(ShapeMutations.SetModel, value);
};
