import { BaseModel } from "@/models/products/base";
import { BaseState } from "./types";
import { Finish } from "@/models/products/finish";
import { Stem } from "@/models/configurator/configurator_options";

export const setBase = (state: BaseState, value: BaseModel) => {
  state.base = value;
};

export const setBaseFinish = (state: BaseState, value: Finish) => {
  state.baseFinish = value;
};

export const setBaseStem = (state: BaseState, value: Stem | null) => {
  state.baseStem = value;
};

export const setBaseOptions = (state: BaseState, value: any) => {
  state.baseOptions = value;
};
