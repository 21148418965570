import { BaseGetters } from "@/store/modules/configurator/base/types";
import { BaseModel } from "@/models/products/base";
import { BaseState } from "./types";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { Finish } from "@/models/products/finish";
import { StoreModule } from "@/store/types";
import { Stem } from "@/models/configurator/configurator_options";

export const getBase = (state: BaseState): BaseModel | null => {
  return state.base;
};

export const getBaseFinish = (state: BaseState): Finish | null => {
  return state.baseFinish;
};

export const getBaseStem = (state: BaseState): Stem | null => {
  return state.baseStem;
};

export const getBaseOptions = (state: BaseState): any => {
  return state.baseOptions;
};

export const getAllBaseData = (
  state: BaseState,
  getters: any,
  rootState: any,
  rootGetters: any
): CustomUmbrella["base"] => {
  const base = {} as CustomUmbrella["base"];
  base.base = rootGetters[StoreModule.Base + BaseGetters.GetBase];
  base.baseFinish = rootGetters[StoreModule.Base + BaseGetters.GetBaseFinish];
  base.baseStem = rootGetters[StoreModule.Base + BaseGetters.GetBaseStem];
  base.baseOptions = rootGetters[StoreModule.Base + BaseGetters.GetBaseOptions];
  return base;
};
