import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { EventBus } from "@/event-bus";
import { FabricSwatch } from "@/models/products/fabric";
import { LayoutStore, ShapeStore, CanopyStore } from "@/mixins/store";
import FabricService from "@/services/fabric_service";
import { StockMixin } from "@/mixins/stock";
import { FabricMixin } from "@/mixins/fabric";
import { ProductLineName } from "@/models/products/collection";

@Component
export class FabricRules extends mixins(LayoutStore, ShapeStore, CanopyStore, StockMixin, FabricMixin) {
  protected Preset = option.Preset;
  //protected allowPocketFabric = false;
  // protected allowVentFabric = false;
  // protected allowValanceFabric = false;
  protected whiteFabric: null | FabricSwatch = null;
  protected fabricService = new FabricService();


  protected async initFabricRules(): Promise<void> {
    this.allowVent(false);
    this.allowValance(false);
    /** Ready waits for the configurator to fire off event bus; we want to make sure it's loaded before we run methods on the viewer */
    EventBus.$on("ready", () => this.readyCanopy);
    /** The changeXXX methods toggle whether the namesake component should sync with the main canopy fabric (changeVent = true means the vent fabric CAN CHANGE independently of the main canopy)
     * If the user explicity changes the fabric of the namesake component, the boolean will be set to true and the component fabric will no longer sync with the main canopy
     * Applies to vent (both top & middle), pocket & valance
     */
    await this.setWhiteFabric();
    //retaining changeXXXX methods when loader is first initialized. if the fabric for each component does not match the main canopy, then turn off auto changing when main canopy fabric changes
    if (!this.isStockOnly && this.mainFabric.length < 2) {
      const main = this.canopyFabric.mfr_code;
      if (this.collection.handle !== ProductLineName.Nova && this.ventTopFabric[0].mfr_code !== main) {
        this.allowVent(true);
      }
      // if (this.ribFabric && this.ribFabric.mfr_code === main) {
      //   this.allowPocketFabric = true;
      // }
      if (this.valanceFabric[0] && this.valanceFabric[0].mfr_code !== main) {
        this.allowValance(true);
      }
    } 
  }

  protected async setWhiteFabric(): Promise<void> {
    let whiteFabricMfrCode = '';
    switch (this.canopyFabric.weight) {
      case ('8') :
        whiteFabricMfrCode = '57003';
        break;
      case ('9') :
        whiteFabricMfrCode = 'R-099';
        break;
    }
    const res = await this.fabricService.getSingleFabric(whiteFabricMfrCode);
    this.whiteFabric = res;
  }

  beforeDestroy() {
    EventBus.$off("ready");
  }

  // protected async ribFabricRules(): Promise<void> {
  //   if (this.rib === option.Rib.Pockets) {
  //     const stripes = this.checkForStripes(this.ribFabric!);
  //     if (stripes) {
  //       this.addRibFabric(this.whiteFabric);
  //       await this.$viewer.ChangeRibAttachment(
  //         option.Rib.Pockets,
  //         this.whiteFabric?.mfr_code
  //       );
  //     }
  //   } else {
  //     this.addRibFabric(null);
  //   }
  // }

  /**
   * Called from watcher in Designer.vue
   *  Auto-toggle items based on value of valances (ex: clear valance binding fabric & valance fabric if user removes valance)
   */
  protected async valanceFabricRules(): Promise<void> {
    if (this.valance === option.Valance.Canopy) {
      this.addBindingFabric(null);
      this.addValanceFabric([]);
      await this.$viewer.ChangeBindingFabric(null);
      //await this.$viewer.ChangeValanceFabric(null, null, null, null); commenting this in bugs the valance
    } else {
      const binding = this.bindingFabric ? this.bindingFabric : this.whiteFabric;
      await this.addBindingFabric(binding);
      await this.$viewer.ChangeBindingFabric(binding!.mfr_code);
      if (this.mainCanopy === option.Panel.Solid) {
        const swatch =
          this.valanceFabric.length > 0
            ? this.valanceFabric[0]
            : this.canopyFabric;
        await this.$viewer.ChangeValanceFabric(swatch.fabric_scale, swatch.mfr_code);
        this.addValanceFabric([swatch]);
      } else if (this.mainCanopy === option.Panel.Alternating) {
        const swatch =
          this.valanceFabric.length > 0
            ? this.valanceFabric[0]
            : this.canopyFabric;
        const swatchAlt =
          this.valanceFabric.length > 1
            ? this.valanceFabric[1]
            : this.mainFabric[1]
              ? this.mainFabric[1]
              : this.canopyFabric;
        this.addValanceFabric([swatch, swatchAlt]);
        await this.$viewer.ChangeValanceFabric(
          swatch.fabric_scale,
          swatch.mfr_code,
          swatch.fabric_scale,
          swatchAlt.mfr_code
        );
      }
    }
  }

  /**
   * Called from watcher in Designer.vue
   * See above EventBus methods in initFabricRules() method for more info
   * Syncs pocket, valance, and/or vent fabric to canopy fabric automatically depending on user action
   * */
  protected async canopyFabricRules(): Promise<void> {
    const swatch = this.canopyFabric;
    const swatchAlt = this.mainFabric[1] ? this.mainFabric[1] : swatch;
    // if (!this.allowPocketFabric && this.canopyFabric) {
    //   const stripes = this.checkForStripes(swatch);
    //   if (stripes) {
    //     this.addRibFabric(this.whiteFabric);
    //     await this.$viewer.ChangeRibAttachment(this.rib, this.whiteFabric!.mfr_code);
    //   } else {
    //     this.addRibFabric(swatch);
    //     await this.$viewer.ChangeRibAttachment(this.rib, swatch.mfr_code);
    //   }
    // }

    /* FABRIC WEIGHT LOGIC BELOW */
    // Alternating Main Canopy Panels
    if (swatchAlt !== swatch && swatchAlt.weight !== swatch.weight) {
      this.addMainFabric([swatch, swatch]);
      await this.$viewer.ChangeCanopyFabric(
        swatch.fabric_scale,
        swatch.mfr_code
      );
    }
    // Top Vent...this also sets the foundation of what's needed to allow for the examination of the middle vent fabrics
    if (
      this.ventTopFabric.length > 0 &&
      this.ventTopFabric[0].weight &&
      this.ventTopFabric[0].weight !== swatch.weight
    ) {
      this.allowVent(false);
    }
    // Valance
    if (this.valance !== option.Valance.Canopy) {
      if (
        this.valanceFabric[0] && this.valanceFabric[0].weight !== swatch.weight ||
        this.valanceFabric[1] && this.valanceFabric[1].weight !== swatch.weight
      ) {
        this.allowValance(false);
      }
    }

    if (!this.allowVentFabric && this.canopyFabric) {
      this.addVentTopFabric([swatch]);
      await this.$viewer.ChangeTopVentFabric(swatch.fabric_scale, swatch.mfr_code);
      if (this.isDoubleVent && this.ventMiddle === option.Panel.Alternating) {
        this.addVentMiddleFabric([swatch, swatch]);
        await this.$viewer.ChangeDblVentFabric(
          swatch.fabric_scale,
          swatch.mfr_code,
          swatch.fabric_scale,
          swatch.mfr_code
        );
      } else if (this.isDoubleVent && this.ventMiddle === option.Panel.Solid) {
        this.addVentMiddleFabric([swatch]);
        await this.$viewer.ChangeDblVentFabric(
          swatch.fabric_scale,
          swatch.mfr_code,
          swatch.fabric_scale,
          swatch.mfr_code
        );
      }
    }
    if (this.valance !== option.Valance.Canopy && !this.allowValanceFabric) {
      if (this.mainCanopy === option.Panel.Solid) {
        this.addValanceFabric([swatch]);
        await this.$viewer.ChangeValanceFabric(swatch.fabric_scale, swatch.mfr_code);
      } else {
        this.addValanceFabric([swatch, swatchAlt]);
        await this.$viewer.ChangeValanceFabric(
          swatch.fabric_scale,
          swatch.mfr_code,
          swatchAlt.fabric_scale,
          swatchAlt.mfr_code
        );
      }
    }
  }

  /**
   *
   * @param {FabricSwatch} swatch
   * @returns {boolean} true if swatch is striped
   */
  protected checkForStripes(swatch: FabricSwatch): boolean {
    let stripes = false;
    if (swatch.pattern_group && swatch.pattern_group.length > 0) {
      const patterns = swatch.pattern_group;
      if (patterns.includes("Stripes")) {
        stripes = true;
      }
    }
    return stripes;
  }

  protected readyCanopy(): void {
    //fabric rules on configurated umbrellas
    // if (this.canopyFabric.mfr_code !== this.ribFabric?.mfr_code) {
      //   this.allowPocketFabric = true;
      // }
    if (
      (this.ventTopFabric[0] &&
        this.canopyFabric.mfr_code !== this.ventTopFabric[0].mfr_code) ||
      (this.isDoubleVent &&
        this.canopyFabric.mfr_code !== this.ventMiddleFabric[0].mfr_code)
    ) {
      this.allowVent(true);
    }
  }

  protected get canopyFabric(): FabricSwatch {
    return this.mainFabric[0];
  }

  protected get isDoubleVent(): boolean {
    let dv = false;
    if (
      this.preset === option.Preset.S1DV ||
      this.preset === option.Preset.D5
    ) {
      dv = true;
    }
    return dv;
  }

  /**
   * Intended to test designer layouts for striped fabrics, which are disallowed
   *
   * @returns {boolean} true if any prohibited area has striped fabric
   */
  protected async checkModelForStripes(): Promise<boolean> {
    let stripes = false;
    const fabrics = [
      ...this.mainFabric,
      ...this.ventTopFabric,
      ...this.ventMiddleFabric
    ];
    for (const swatch of fabrics) {
      if (swatch && this.checkForStripes(swatch)) {
        stripes = true;
        break;
      }
    }
    return stripes;
  }

  protected async toggleStripeWarning(): Promise<void> {
    EventBus.$emit("toggleStripesModal");
  }
}
