import axios, { AxiosResponse } from "axios";
import {
  ProductListRes,
  ProductType,
  SingleProductRes,
  SingleProductWithBasesRes
} from "@/models/products/product";
import { PriceRes, PriceTotal } from "@/models/pricing/price";
import { Collection } from "@/models/products/collection";
import {
  APIError,
  AuthError,
  NotFoundError,
  PermissionsError
} from "./error_service";
import { UmbrellaData } from "@/mixins/umbrella";
import { User } from "@/models/user/user";
import { ConfigFile } from "@/models/configurator/config_file";


/**
 * Creates a new Product Service

 * @example
 * const productService = new ProductService();
 * productService.getUmbrellas();
 */

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

export default class ProductService {
  public async getUmbrellas(): Promise<ProductListRes> {
    let umbrellas;
    const url =
      process.env.VUE_APP_API_NODE_URL +
      "/products?category=" +
      ProductType.Umbrella +
      "&sort=productline";
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      umbrellas = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Umbrellas not found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return umbrellas;
  }

  public async getBases(model?: string): Promise<ProductListRes> {
    let bases: ProductListRes;
    let url = "";
    if (model) {
      url = process.env.VUE_APP_API_NODE_URL + "/products/" + model + "?bases";
    } else {
      url =
        process.env.VUE_APP_API_NODE_URL +
        "/products?category=" +
        ProductType.Base +
        "&sort=productline";
    }
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      bases = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Bases not found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return bases;
  }

  public async getAccessories(): Promise<ProductListRes> {
    let accs;
    const url =
      process.env.VUE_APP_API_NODE_URL +
      "/products?category=" +
      ProductType.Accessory +
      "&sort=productline";
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      accs = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Accessories not found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return accs;
  }

  public async getChairs(): Promise<ProductListRes> {
    let chairs;
    const url =
      process.env.VUE_APP_API_NODE_URL +
      "/products?category=" +
      ProductType.Chair +
      "&sort=productline";
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      chairs = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Chairs not found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return chairs;
  }

  public async getSingleProduct(
    model: string,
    bases?: boolean
  ): Promise<SingleProductRes | SingleProductWithBasesRes> {
    let product;
    let url = process.env.VUE_APP_API_NODE_URL + "/products/" + model;
    if (bases) {
      url += "?bases";
    }
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      product = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Product not found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return product;
  }

  public async getProducts(optionsObject?: {
    category?: ProductType;
    pl?: string;
    sort?: "productline";
  }): Promise<Collection[]> {
    let product;
    let url = process.env.VUE_APP_API_NODE_URL + "/products";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.category) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "category=" + optionsObject.category;
      }
      if (optionsObject.pl) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "pl=" + optionsObject.pl;
      }
      if (optionsObject.sort) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "sort=" + optionsObject.sort;
      }
    }
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      product = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("No products found.", err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return product;
  }

  public async getPrice(model: string): Promise<PriceRes> {
    let price;
    const url = process.env.VUE_APP_API_NODE_URL + "/prices/" + model;
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url, options);
      price = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Unable to get price data. Please try again.",
          err.response.status
        );
      }
    }
    return price;
  }

  public async getTotal(): Promise<PriceTotal> {
    let price;
    const umbrellaClass = new UmbrellaData();
    const body = umbrellaClass.getUmbrellaObject();
    const url = process.env.VUE_APP_API_NODE_URL + "/prices/";
    try {
      const res: AxiosResponse = await axios.post(url, body);
      price = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Unable to get price data. Please try again.",
          err.response.status
        );
      }
    }
    return price;
  }

  public async shareShadeDesign(shareLink: string, shareEmails: string, user: User): Promise<boolean> {
    let success = false;
    const body = {
      link: shareLink,
      emails: shareEmails,
      user: user
    }
    const url = process.env.VUE_APP_API_NODE_URL + '/products/share';
    try {
      const res: AxiosResponse = await axios.post(url, body);
      if (res.status === 200) {
        success = true;
      }
    } catch (err) {
      console.log(err);
    }
    return success;
  }

  public async createFinishedUmbrellaSKU(data: ConfigFile): Promise<string> {
    let sku = ""
    const url = process.env.VUE_APP_API_NODE_URL + '/products/sku';

    try {
      const res: AxiosResponse = await axios.post(url, data);
      if (res.data) {
        sku = res.data.finishedUmbrellaSKU;
      }
    } catch (error) {
      sku = "Not Available...";
    }
    return sku;
  }
}
