import {
  ConfigFile,
} from "@/models/configurator/config_file";
import { ContestEntry } from "@/models/contest";
import axios, { AxiosResponse } from "axios";
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from "./error_service";

axios.defaults.baseURL = process.env.VUE_APP_API_NODE_URL;
axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

export default class ContestService {

  public async getFile(id: string): Promise<ContestEntry> {
    let file;
    const url = "/contest/" + id;
    try {
      const res: AxiosResponse = await axios.get(url);
      file = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Contest file not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return file;
  }

  public async deleteEntry(id: string): Promise<void> {
    const url = "/contest/" + id;
    try {
      await axios.delete(url);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Contest file not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }

  public async getEntries(): Promise<ContestEntry[]> {
    let files;
    const url = "/contest";
    try {
      const res: AxiosResponse = await axios.get(url);
      files = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Contest files not found.",
          err.response.status
        );
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return files;
  }

  /**
   * @param {ConfigFile} req request body
   * @returns {ConfigFile} - newly created config file with _id
   */
  public async createFile(
    entry: ContestEntry,
    configFile: ConfigFile,
    images: string[]
  ): Promise<void> {
    const url = "/contest";
    try {
      await axios.post(url, { config_file: {...configFile, image: images[0]}, entry: {...entry} });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }
}
