import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { FabricState } from "./types";


const state = {
  allowValance: false,
  allowVent: false
} as FabricState;

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
