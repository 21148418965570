import { Preset } from "@/models/configurator/configurator_options";
import { Collection } from "@/models/products/collection";
import { LayoutState } from "./types";

export const getCollection = (state: LayoutState): Collection | null => {
  return state.collection;
};

export const getPreset = (state: LayoutState): Preset | null => {
  return state.preset;
};
