import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { LayoutStore, ShapeStore, CanopyStore } from "@/mixins/store";
import { UmbrellaModel } from "@/models/products/umbrella";
import { ProductLineName } from "@/models/products/collection";

@Component
export class AttributeRules extends mixins(
  LayoutStore,
  ShapeStore,
  CanopyStore
) {
  protected hasVent = true;
  protected hasValance = true;
  protected showValanceAsDefault = [
    ProductLineName.Avalon,
    ProductLineName.EmeraldCoast,
    ProductLineName.Laurel,
    ProductLineName.Catalina
  ];

  protected tilted = false;
  protected ProductLineName = ProductLineName;
  protected tiltModels = ["845AT", "845CT", "844FC01", "844FC02", "844FC03"];
  protected tiltProductLines = [
    ProductLineName.MontereyAutoTilt,
    ProductLineName.Eclipse,
    ProductLineName.Aurora
  ];



  get tiltAllowed(): boolean {
    return (this.tiltProductLines.includes(this.collection.handle as ProductLineName) || this.tiltModels.includes(this.umbrellaModel!.model));
  }

  protected async tilt(): Promise<void> {
    this.tilted = !this.tilted;
    await this.$viewer.TiltUmbrella(this.tilted);
  }
  /**
   * Reads umbrella model data & sets hasVent & hasValance, which toggle display of vent & valance items in the canopy components
   *
   * See fabric_rules.ts mixin for valance fabric toggles
   */
  protected async checkModelAttributes(): Promise<void> {
    const currentModel: UmbrellaModel = this.umbrellaModel!;
    if (!this.tiltAllowed && this.tilted) {
      this.tilted = false;
      await this.$viewer.TiltUmbrella(this.tilted)
    }
    if (currentModel.attributes) {
      if (
        Object.prototype.hasOwnProperty.call(currentModel.attributes, "valance")
      ) {
        this.hasValance = currentModel.attributes.valance;
      }
      if (
        Object.prototype.hasOwnProperty.call(currentModel.attributes, "vent")
      ) {
        this.hasVent = currentModel.attributes.vent;
      }
      if (
        !this.hasValance ||
        (this.preset !== option.Preset.S1 && this.preset !== option.Preset.S1DV)
      ) {
        this.addValance(option.Valance.Canopy);
        if (this.$viewer) {
          this.$nextTick(async() => {
            await this.$viewer.ToggleValance(false);
          });
        }
      } else {
        if (
          this.showValanceAsDefault.includes(
            this.collection.handle as ProductLineName
          )
        ) {
          this.addValance(option.Valance.Standard);
          if (this.$viewer) {
          this.$nextTick(async () => {
            const swatch =
            this.ventTopFabric.length > 0
              ? this.ventTopFabric[0]
              : this.mainFabric[0];
            await this.$viewer.ToggleValance(true);
            await this.$viewer.ChangeValance(option.Valance.Standard);
            await this.$viewer.ChangeValanceFabric(
              swatch.fabric_scale,
              swatch.mfr_code
            );
          });
        }
        }
      }
      if (!this.hasVent) {
        if (this.$viewer) {
          await this.$viewer.ToggleVent(false);
        }
        this.addVentTopFabric([]);
      } else {
        const swatch =
          this.ventTopFabric.length > 0
            ? this.ventTopFabric[0]
            : this.mainFabric[0];
        this.addVentTopFabric([swatch]);
        if (this.$viewer) {
          this.$nextTick(async () => {
            await this.$viewer.ToggleVent(true);
            await this.$viewer.ChangeTopVentFabric(
              swatch.fabric_scale,
              swatch.mfr_code
            );
          });
        }
      }
    }
  }
}
