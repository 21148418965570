import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { UserActions, UserGetters } from "@/store/modules/user/types";
import { User } from "@/models/user/user";
import { Component, Vue } from "vue-property-decorator";
import { Roles } from "@/models/user/roles";

@Component
export class Auth extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoggedIn))
  isLoggedIn!: boolean;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetRole))
  userRole!: Roles;
  @(namespace(StoreModule.User).Getter(UserGetters.GetUser))
  user!: User;
  @(namespace(StoreModule.User).Getter(UserGetters.GetFileId))
  fileBeingEditedId!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoggedIn))
  setLoggedIn!: (value: boolean) => Promise<void>;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddRole))
  setUserRole!: (value: Roles | null) => Promise<void>;
  @(namespace(StoreModule.User).Action(UserActions.AddUser))
  setUserInfo!: (value: User) => Promise<void>;
  @(namespace(StoreModule.User).Action(UserActions.AddFileId))
  addFileBeingEditedId!: (value: string) => Promise<void>;
}
