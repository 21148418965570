import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import axios, { AxiosResponse } from "axios";
import { APIError, BadRequest, NotFoundError } from "./error_service";

// import { AuthError, APIError } from "@/services/error_service";

/**
 * Creates a new Config File Service

 * @example
 * const specService = new SpecService();
 * specService.getFile();
 */

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;
axios.defaults.withCredentials = true;

export default class SpecService {
  /**
   *
   */
  public async downloadSpecs(req: {
    images: string[];
    specs: CustomUmbrella;
  }): Promise<Blob> {
    let pdf;
    const url = process.env.VUE_APP_API_NODE_URL + "/specs";
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.post(url, req, {
        responseType: "blob"
      });
      pdf = res.data;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(
          "Configuration file not found.",
          err.response.status
        );
      } else {
        if (err.response && err.response.status === 400) {
          throw new BadRequest(err.response.data.message, err.response.status);
        } else {
          throw new APIError(
            "Something went wrong, please try again.",
            err.response.status
          );
        }
      }
    }
    return pdf;
  }
}
