import { Preset } from "@/models/configurator/configurator_options";
import { Collection } from "@/models/products/collection";
import { LayoutState } from "./types";

export const setCollection = (state: LayoutState, value: Collection | null) => {
  state.collection = value;
};

export const setPreset = (state: LayoutState, value: Preset) => {
  state.preset = value;
};
