import { UserState } from "./types";
import { User } from "@/models/user/user";

export const setUser = (state: UserState, data: User) => {
  state.user = data;
};

export const setFileId = (state: UserState, data: string | null) => {
  state.fileBeingEdited = data;
};
