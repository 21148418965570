import { render, staticRenderFns } from "./RadioInput.vue?vue&type=template&id=533ea9d5&scoped=true&"
import script from "./RadioInput.vue?vue&type=script&lang=ts&"
export * from "./RadioInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533ea9d5",
  null
  
)

export default component.exports