import * as option from "@/models/configurator/configurator_options";

import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { FrameContext, FrameMutations } from "./types";

import { Finish } from "@/models/products/finish";

export const addFinish = (context: FrameContext, value: Finish) => {
  context.commit(FrameMutations.SetFinish, value);
};

export const addFinial = (context: FrameContext, value: option.Finial) => {
  context.commit(FrameMutations.SetFinial, value);
};

export const addFinialFinish = (context: FrameContext, value: Finish) => {
  context.commit(FrameMutations.SetFinialFinish, value);
};

export const addBottomPole = (
  context: FrameContext,
  value: option.BottomPole | null
) => {
  context.commit(FrameMutations.SetBottomPole, value);
};

export const addSpigot = (
  context: FrameContext,
  value: option.Spigot | null
) => {
  context.commit(FrameMutations.SetSpigot, value);
};

export const addAllFrameData = (
  context: FrameContext,
  value: CustomUmbrella["frame"]
) => {
  context.commit(FrameMutations.SetFinish, value.finish);
  context.commit(FrameMutations.SetFinial, value.finial);
  context.commit(FrameMutations.SetFinialFinish, value.finialFinish);
  context.commit(FrameMutations.SetBottomPole, value.bottomPole);
  context.commit(FrameMutations.SetSpigot, value.spigot);
};
