import { LayoutContext, LayoutMutations } from "./types";

import { Collection } from "@/models/products/collection";
import { Preset } from "@/models/configurator/configurator_options";

export const addCollection = (
  context: LayoutContext,
  value: Collection | null
) => {
  context.commit(LayoutMutations.SetCollection, value);
};

export const addPreset = (context: LayoutContext, value: Preset) => {
  context.commit(LayoutMutations.SetPreset, value);
};
