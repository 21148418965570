

































































import { Component } from "vue-property-decorator";
import { ShapeStore } from "@/mixins/store";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";

@Component
export default class Menu extends mixins(ShapeStore, Auth) {
  protected Roles = Roles;

  get disabled(): boolean {
    let disabled = false;
    if (!this.umbrellaModel || !this.umbrellaModel.model) {
      disabled = true;
    }
    return disabled;
  }
}
