import UIkit from "uikit";
import store from "@/store/index";
import router from "@/router/index";
import { StoreModule } from "@/store/types";
import { GlobalActions } from "@/store/modules/global/types";

export class AuthError extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static logout(): void {
    store.dispatch(StoreModule.Global + GlobalActions.AddLoggedIn, false);
    store.dispatch(StoreModule.Global + GlobalActions.AddRole, null);
    UIkit.notification({
      message: "Your session has expired. Please login again.",
      status: "danger"
    });
  }
}

export class PermissionsError extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static popup(message: string, statusCode: number) {
    UIkit.notification({
      message: statusCode + " : " + message,
      status: "danger"
    });
  }
}

export class LoginError extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static popup(message: string, statusCode: number) {
    UIkit.notification({
      message: statusCode + " : " + message,
      status: "danger"
    });
  }
}
export class GenericError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class APIError extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static popup(message: string, statusCode: number) {
    UIkit.notification({
      message: statusCode + " : " + message,
      status: "danger"
    });
  }
  static redirect(message: string) {
    router.push({
      name: "Error",
      params: {
        message
      }
    });
  }
}

export class NotFoundError extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static popup(message: string, statusCode: number) {
    UIkit.notification({
      message: statusCode + " : " + message,
      status: "danger"
    });
  }
  static redirect(message: string) {
    router.push({
      name: "Error",
      params: {
        message
      }
    });
}
}

export class BadRequest extends Error {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode: number;
  static popup(message: string, statusCode: number) {
    UIkit.notification({
      message: statusCode + " : " + message,
      status: "danger"
    });
  }
}



export class ViewerError extends Error {
  static redirect(err?: string) {
    router.push("/error");
  }
}
