

















































































import { Component, Prop } from "vue-property-decorator";
import UIkit from "uikit";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";
import AuthService from "@/services/auth_service";
import { AuthError, GenericError } from "@/services/error_service";

@Component
export default class Header extends mixins(Auth) {
  @Prop({ default: false }) profileView?: boolean;
  protected authService = new AuthService();

  protected toggleModal(): void {
    UIkit.modal(
      document.getElementById("start-over-modal") as HTMLElement
    ).show();
  }

  public async logout(): Promise<void> {
    try {
      this.addFileBeingEditedId("");
      this.setLoggedIn(false);
      this.setUserRole(null);
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }
}
