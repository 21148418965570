import { ConfigFile } from "../configurator/config_file";
import { Accessory } from "./accessory";
import { BaseModel } from "./base";
import { ChairModel } from "./chair";
import { ProductType } from "./product";
import { UmbrellaModel, UmbrellaType } from "./umbrella";

export interface Collection {
  _id: string;
  handle: string;
  display_name: string;
  image?: string;
  type: ProductType;
  umbrella_type?: UmbrellaType;
  specific_mounting?: boolean;
  models: BaseModel[] | UmbrellaModel[] | Accessory[] | ChairModel[];
  config_file?: ConfigFile[];
}

export enum ProductLineName {
  MontereyPulleyLift = "monterey_pulley_lift",
  MontereyAutoTilt = "monterey_auto_tilt",
  MontereyCrank = "monterey_crank",
  MontereyGSeries = "monterey_g_series",
  Greenwich = "greenwich",
  GreenwichGSeries = "greenwich_g_series",
  Laurel = "laurel",
  Catalina = "catalina",
  Aurora = "aurora",
  Avalon = "avalon",
  CartUmbrella = "cart_umbrella",
  Eclipse = "eclipse",
  EmeraldCoast = "emerald_coast",
  Lifeguard = "lifeguard",
  Nova = "nova",
  ShadeStar = "shade_star"
}
