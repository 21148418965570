















































import { EventBus } from "@/event-bus";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class SaveUmbrellaModal extends Vue {
  @Prop() editMode!: boolean;
  @Prop() dialog!: boolean;
  protected title = "";
  protected bodyText = "";
  protected fileName = "";
  protected loader: any = null;
  protected images: string[] = [];
  protected saveAsNew = false;

  protected saveDesign(): void {
    this.$emit("confirmSave", this.saveAsNew, this.images, this.fileName);
  }

  protected close(): void {
    this.saveAsNew = false;
    this.images = [];
    this.$emit("close");
  }

  protected toggleSave(): void {
    this.title = "Your umbrella looks great!";
    this.bodyText = "Please name your new design."
    this.saveAsNew = true;
  }

  protected saveImages(images: string[]): void {
    this.images = images;
    setTimeout(() => {
      this.loader.hide();
    }, 500);
  }

  @Watch("dialog", { immediate: true })
  protected onOpen(): void {
    if (this.dialog) {
      this.saveAsNew = !this.editMode;
      EventBus.$emit("getStaticImages", this.saveImages);
      this.loader = this.$loading.show({ opacity: 0.5 });
      this.title = !this.editMode
        ? "Your umbrella looks great!"
        : "Are you sure you want to save?";
      this.bodyText = !this.editMode
        ? "Please name your design."
        : "Any changes made will override your current design.";
    }
  }
}
